import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import PartNumber from '../../../../../../PartNumber'
import LabelSubtitle from '../../../../../../../typo/LabelSubtitle'
import StatusTypo from '../../../../../../../typo/StatusTypo'
import { STATUS_PRODUCT } from '../../../../../../../../utils/constants'
import { LOCAL_STORAGE_KEYS } from '../../../../../../../../config/enums'
import { SOURCE_VALUE } from '../../../../../../../../modules/selector/utils/constants'
import { PartDetailDialog } from '../../../../../../PartDetailDialog'
import { CustomStoreElementsContext } from "../../../../../../../../utils/contextes/CustomStoreElementsContext";

const FittingItemLeftSection: FC<FittingItemType> = ({ source }) => {
	const { t } = useTranslation()
	const { nomCommercial } = useContext(CustomStoreElementsContext)

	const storePartNumber = source === SOURCE_VALUE.FITTING_A
		? localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER)!
		: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER)!

	return (
		<Stack
			boxSizing="border-box"
			flexDirection="column"
			justifyContent="space-between"
			alignItems="flex-start"
			gap="4px"
			alignSelf="stretch"
			flexGrow="1"
			borderRight="2px solid rgba(0, 0, 0, 0.1)"
		>
			<Stack flexDirection="column" alignItems="flex-start" gap="4px">
				<LabelSubtitle>
					{
						source === SOURCE_VALUE.FITTING_A
							? localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SERIES_NAME)!
							: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SERIES_NAME)!
					}
				</LabelSubtitle>
				<StatusTypo color="#828282">
					{t(`${((source === SOURCE_VALUE.FITTING_A && localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER)) || (source === SOURCE_VALUE.FITTING_B && localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER))) ? t(`${STATUS_PRODUCT.REGULAR}`)  : t(`${STATUS_PRODUCT.ON_REQUEST}`)}`)}
				</StatusTypo>
				<PartNumber
					partNumber={t('components.assemblyProgressionPanel.partNumber', { partNumber: source === SOURCE_VALUE.FITTING_A ? localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER)! : localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER)! })}
				/>
				{
					storePartNumber &&
					<PartNumber partNumber={t('components.assemblyProgressionPanel.storePartNumber', { partNumber: storePartNumber, store: !!nomCommercial ? nomCommercial : "STORE" } )} />
				}
			</Stack>
			<PartDetailDialog
				partNumber={source === SOURCE_VALUE.FITTING_A
					? localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER)!
					: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER)!}
				seriesName={source === SOURCE_VALUE.FITTING_A
					? localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SERIES_NAME)!
					: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SERIES_NAME)!}
			/>
		</Stack>
	)
}

export default FittingItemLeftSection
