import React, {createContext, FC, useEffect, useState} from "react";
import { getCustomStoreElements } from "../services/storesApi";
import { extractDistributorToken } from "../utils";
import { LOCAL_STORAGE_KEY } from "../constants";

type CustomStoreElementsContext = {
    delaiStandard: string | null,
    delaiOnRequest: string | null,
    mailDestinataireBOM: string | null,
    titleBlocHome: string | null,
    textBlocHome: string | null,
    labelButtonBlocHome: string | null,
    linkButtonBlocHome: string | null,
    mailContact: string | null,
    telContact: string | null,
    jauneParker: string | null,
    noirParker: string | null,
    blancParker: string | null,
    customLanguages: string[],
    isCustomStore: boolean,
    typePrix: string | null,
    prixFixe: number | null,
    tauxHoraire: number | null,
    dureesAssemblage: number[] | null,
    nomCommercial: string | null,
    quote: string | null,
    link: string | null,
    labelLink: string | null,
    isLoading: boolean
}

type CustomStoreElementsContextProviderType = {
    children: any
}

export const CustomStoreElementsContext = createContext<CustomStoreElementsContext>({
    delaiStandard: null,
    delaiOnRequest: null,
    mailDestinataireBOM: null,
    titleBlocHome: null,
    textBlocHome: null,
    labelButtonBlocHome: null,
    linkButtonBlocHome: null,
    mailContact: null,
    telContact: null,
    jauneParker: null,
    noirParker: null,
    blancParker: null,
    customLanguages: [],
    isCustomStore: false,
    typePrix: null,
    prixFixe: null,
    tauxHoraire: null,
    dureesAssemblage: null,
    nomCommercial: null,
    quote: null,
    link: null,
    labelLink: null,
    isLoading: false
})

export const CustomStoreElementsContextProvider: FC<CustomStoreElementsContextProviderType> = ({children}) => {
    let token = extractDistributorToken()
    if (!token) {
        const storedToken = localStorage.getItem(LOCAL_STORAGE_KEY.STORE_TOKEN)
        if (!!storedToken) {
            token = storedToken
        }
    }
    const [ delaiStandard, setDelaiStandard] = useState<string>("")
    const [ delaiOnRequest, setDelaiOnRequest] = useState<string>("")
    const [ mailDestinataireBOM, setMailDestinataireBOM] = useState<string | null>(null)
    const [ customLanguages, setCustomLanguages ] = useState<string[]>([])
    const [ titleBlocHome, setTitleBlocHome] = useState<string | null>(null)
    const [ textBlocHome, setTextBlocHome] = useState<string | null>(null)
    const [ labelButtonBlocHome, setLabelButtonBlocHome] = useState<string | null>(null)
    const [ linkButtonBlocHome, setLinkButtonBlocHome] = useState<string | null>(null)
    const [ mailContact, setMailContact ] = useState<string | null>(null)
    const [ telContact, setTelContact ] = useState<string | null>(null)
    const [ jauneParker, setJauneParker] = useState<string | null>(null)
    const [ noirParker, setNoirParker] = useState<string | null>(null)
    const [ blancParker, setBlancParker] = useState<string | null>(null)
    const [ isCustomStore, setIsCustomStore] = useState<boolean>(false)
    const [ typePrix, setTypePrix] = useState<string | null>(null)
    const [ prixFixe, setPrixFixe] = useState<number | null>(null)
    const [ tauxHoraire, setTauxHoraire] = useState<number | null>(null)
    const [ dureesAssemblage, setDureesAssemblage] = useState<number[] | null>(null)
    const [ nomCommercial, setNomCommercial] = useState<string | null>(null)
    const [ quote, setQuote] = useState<string | null>(null)
    const [ link, setLink] = useState<string | null>(null)
    const [ labelLink, setLabelLink] = useState<string | null>(null)
    const [ isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (token) {
            setIsLoading(true)
            getCustomStoreElements(token)
                .then(data => {
                    if (Object.keys(data).length > 0) {
                        setIsCustomStore(true)
                        if (data.hasOwnProperty("info_delai_produits_standards")) {
                            setDelaiStandard(data.info_delai_produits_standards)
                        }
                        if (data.hasOwnProperty("info_delai_produits_on_request")) {
                            setDelaiOnRequest(data.info_delai_produits_on_request)
                        }
                        if (data.hasOwnProperty("email_destinataire_b_o_m")) {
                            setMailDestinataireBOM(data.email_destinataire_b_o_m)
                        }
                        if (data.hasOwnProperty("langues_autorisees")) {
                            setCustomLanguages(data.langues_autorisees)
                        }
                        if (data.hasOwnProperty("home_bloc_title")) {
                            setTitleBlocHome(data.home_bloc_title)
                        }
                        if (data.hasOwnProperty("home_bloc_text")) {
                            setTextBlocHome(data.home_bloc_text)
                        }
                        if (data.hasOwnProperty("home_bloc_button_label")) {
                            setLabelButtonBlocHome(data.home_bloc_button_label)
                        }
                        if (data.hasOwnProperty("home_bloc_button_link")) {
                            setLinkButtonBlocHome(data.home_bloc_button_link)
                        }
                        if (data.hasOwnProperty("email_contact")) {
                            setMailContact(data.email_contact)
                        }
                        if (data.hasOwnProperty("telephone_contact")) {
                            setTelContact(data.telephone_contact)
                        }
                        if (data.hasOwnProperty("couleur_remplacement_jaune_parker")) {
                            setJauneParker(data.couleur_remplacement_jaune_parker)
                        }
                        if (data.hasOwnProperty("couleur_remplacement_noir_parker")) {
                            setNoirParker(data.couleur_remplacement_noir_parker)
                        }
                        if (data.hasOwnProperty("couleur_remplacement_blanc_parker")) {
                            setBlancParker(data.couleur_remplacement_blanc_parker)
                        }
                        if (data.hasOwnProperty("type_tarif_service")) {
                            setTypePrix(data.type_tarif_service)
                        }
                        if (data.hasOwnProperty("prix_fixe")) {
                            setPrixFixe(data.prix_fixe)
                        }
                        if (data.hasOwnProperty("taux_horaire")) {
                            setTauxHoraire(data.taux_horaire)
                        }
                        if (data.hasOwnProperty("durees_assemblage")) {
                            setDureesAssemblage(data.durees_assemblage)
                        }
                        if (data.hasOwnProperty("nom_commercial")) {
                            setNomCommercial(data.nom_commercial)
                        }
                        if (data.hasOwnProperty("titre_conditions_vente")) {
                            setQuote(data.titre_conditions_vente)
                        }
                        if (data.hasOwnProperty("lien_conditions_vente")) {
                            setLink(data.lien_conditions_vente)
                        }
                        if (data.hasOwnProperty("label_lien_conditions_vente")) {
                            setLabelLink(data.label_lien_conditions_vente)
                        }
                    }
                })
                .catch(() => setIsCustomStore(false))
                .finally(() => setIsLoading(false))
        }
    }, [])

    return <CustomStoreElementsContext.Provider value={{
        delaiStandard,
        delaiOnRequest,
        mailDestinataireBOM,
        titleBlocHome,
        textBlocHome,
        labelButtonBlocHome,
        linkButtonBlocHome,
        mailContact,
        telContact,
        jauneParker,
        blancParker,
        noirParker,
        customLanguages,
        isCustomStore,
        typePrix,
        prixFixe,
        tauxHoraire,
        dureesAssemblage,
        nomCommercial,
        quote,
        link,
        labelLink,
        isLoading
    }}>
        {children}
    </CustomStoreElementsContext.Provider>
}