import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import logoParkerStore from '../../../assets/img/global/ParkerLogo_White.png'
import { HiOutlineEllipsisVertical } from 'react-icons/hi2'
import NavbarLink from './NavbarLink'
import NavbarButton from './NavbarButton'
import { AppBar, Link, Stack, styled, Typography } from '@mui/material'
import NavbarLanguageSelector from './NavbarLanguageSelector'
import { DistributorContext } from '../../../utils/contextes/DistributorContext'
import { clearLocalStorage } from "../../../utils/selectorUtils";
import { useLocation } from 'react-router-dom'
import { PicturesContext } from "../../../utils/contextes/PicturesContext";
import { CustomStoreElementsContext } from "../../../utils/contextes/CustomStoreElementsContext";

const NavbarContainer = styled(AppBar)(
	({ theme }) => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.navbar.startGradient,
		backdropFilter: 'blur(3px)',
		padding: '0 48px',
		height: '90px'
	})
)

const LogoContainer = styled(Link)(
	({theme}) => ({
		flexDirection: 'column',
		alignItems: 'center',
		marginRight: '12px',
		cursor: 'pointer'
	})
)

const StoreName = styled(Typography)(
	({ theme }) => ({
		color: theme.palette.text.secondary,
		fontFamily: 'DIN 2014',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '34px'
	})
)

const ParkerLogo = styled(`img`)({
	height: '40px',
	marginRight: '20px'
})

const Navbar = () => {
	const { t } = useTranslation()
	const { token, storeInformation } = useContext(DistributorContext)
	const { customLogo } = useContext(PicturesContext)
	const { nomCommercial } = useContext(CustomStoreElementsContext)
	const location = useLocation()


	return (
		<NavbarContainer position="sticky">
			<Stack flexDirection="row">
				<LogoContainer underline="none" href={`/?token=${token}`}>
					<ParkerLogo src={ logoParkerStore } alt="" />
					{
						!!customLogo && customLogo != "" && <ParkerLogo src={`data:image;base64,${customLogo}` } alt="" />
					}
				</LogoContainer>
				{
					!!nomCommercial
						? <StoreName variant="h6">{nomCommercial}</StoreName>
						: storeInformation && <StoreName variant="h6">{storeInformation.name}</StoreName>
				}
			</Stack>
			<Stack direction="row" divider={<HiOutlineEllipsisVertical color="#828282" />} justifyContent="center" alignItems="center">
				<NavbarLink href={`/?token=${token}`}>{t('navigation.home')}</NavbarLink>
				<NavbarLink onClick={() => window.location.href='/admin'}>{t('navigation.admin')}</NavbarLink>
				<NavbarButton>
					<NavbarLink inButton href={`/selector?token=${token}`} onClick={() => clearLocalStorage()}>
						{t('navigation.selector')}
					</NavbarLink>
				</NavbarButton>
				<NavbarLanguageSelector />
			</Stack>
		</NavbarContainer>
	)
}

export default Navbar
