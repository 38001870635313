import React, {FC, useContext, useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, styled } from '@mui/material'
import PrimaryTitle from '../../../../../typo/PrimaryTitle'
import LabelSubtitle from '../../../../../typo/LabelSubtitle'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'
import CheckIcon from '@mui/icons-material/Check'
import LoadingButton from '@mui/lab/LoadingButton'
import {pdfBilling} from "../../../../../../modules/selector/services/pdfApi";
import {LOCAL_STORAGE_KEYS} from "../../../../../../config/enums";
import {SnackContext} from "../../../../../../utils/contextes/SnackContext";
import { DistributorContext } from '../../../../../../utils/contextes/DistributorContext'
import {CustomStoreElementsContext} from "../../../../../../utils/contextes/CustomStoreElementsContext";
import PersonalInformationModal from "../../../../../modals/recap/PersonalInformationModal";

const Container = styled(Stack)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '24px',
		gap: '24px',
		background: '#D1FAE5',
		border: `0 solid ${theme.palette.primary.main}`,
		borderRadius: '12px',
		alignSelf: 'stretch'
	})
)

const CheckContainer = styled(Stack)(
	({theme}) => ({
		border: `2px solid ${theme.palette.success.main}`,
		borderRadius: '50px',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '3px'
	})
)

const DownloadButton = styled(LoadingButton)({
	padding: '14px 24px',
	gap: '12px',
	borderRadius: '6px'
})

const RecapHeader: FC = () => {
	const { setVisibilitySnackbar } = useContext(SnackContext)
	const { token } = useContext(DistributorContext)
	const { mailDestinataireBOM, isCustomStore } = useContext(CustomStoreElementsContext)
	const { t, i18n } = useTranslation()
	const [pdfLoading, setPdfLoading] = useState(false)
	const [pdfErrorLoading, setPdfErrorLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [sendByMail, setSendByMail] = useState(false)
	const hoseCuttingLength: number = useMemo(() => Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH)) - Number(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_CONNEXION_LENGTH_MM)) - Number(localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_CONNEXION_LENGTH_MM)), [localStorage])

	const setDownloadType = (mail: boolean ) => {
		setShowModal(true)
		setSendByMail(mail)
	}

	const handleDownloadPdf = () => {
		setPdfLoading(true)
		pdfBilling(JSON.stringify({
			language: i18n.language,
			hose_part_number: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER),
			fitting_part_number_1: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_PART_NUMBER),
			fitting_part_number_2: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_PART_NUMBER),
			total_length: localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH),
			cutting_length: hoseCuttingLength,
			fitting_angle: localStorage.getItem(LOCAL_STORAGE_KEYS.FITTINGS_ANGLE),
			assembly_max_working_pressure: localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE),
			quantity: localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY),
			store_token: token
		}))
			.then(pdf => {
				if (pdf) {
					const fileURL = URL.createObjectURL(pdf)
					window.open(fileURL)
				}
			})
			.catch(() => {
				setPdfErrorLoading(true)
				setVisibilitySnackbar(true)
			})
			.finally(() => setPdfLoading(false))
	}

	return (
		<Container>
			<Stack flexDirection="row" alignItems="flex-start" gap="24px" flexGrow="1">
				<CheckContainer>
					<CheckIcon color="success" />
				</CheckContainer>
				<Stack gap="10px">
					<PrimaryTitle recap>{t('screens.selector.assembly.recap.title')}</PrimaryTitle>
					<LabelSubtitle color="#064E3B">{t('screens.selector.assembly.recap.subtitle')}</LabelSubtitle>
				</Stack>
			</Stack>
			<DownloadButton loading={pdfLoading} color="primary" variant="contained" disabled={pdfErrorLoading} endIcon={<SimCardDownloadIcon color={pdfErrorLoading ? "disabled" : "secondary"} fontSize="large" />} onClick={() => isCustomStore && !!mailDestinataireBOM ? setDownloadType(true) : handleDownloadPdf()}>
				{
					isCustomStore ?	t('screens.selector.assembly.recap.send') : t('screens.selector.assembly.recap.download')
				}
			</DownloadButton>
			{
				isCustomStore &&
				<DownloadButton loading={pdfLoading} color="primary" variant="contained" disabled={pdfErrorLoading} endIcon={<SimCardDownloadIcon color={pdfErrorLoading ? "disabled" : "secondary"} fontSize="large" />} onClick={() => isCustomStore && !!mailDestinataireBOM ? setDownloadType(false) : handleDownloadPdf()}>
					{ t('screens.selector.assembly.recap.downloadCustomStore') }
				</DownloadButton>
			}
			<PersonalInformationModal
				open={showModal}
				byMail={sendByMail}
				hoseCuttingLength={hoseCuttingLength}
				onError={() => setPdfErrorLoading(true)}
				handleClose={() => setShowModal(false)}
				handleConfirm={() => setShowModal(false)}/>
		</Container>
	)
}

export default RecapHeader
